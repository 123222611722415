'use client';

import React from 'react';
import { Card, Col, Row, Typography, Layout, Button, Divider } from 'antd';
import { BookOutlined, BranchesOutlined, CalculatorOutlined, CheckCircleOutlined, ClusterOutlined, CustomerServiceOutlined, DatabaseOutlined, DownloadOutlined, ExportOutlined, FileAddOutlined, FileSearchOutlined, FileSyncOutlined, GlobalOutlined, LineChartOutlined, PartitionOutlined, ProfileOutlined, SearchOutlined, TableOutlined, TeamOutlined, UserOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { useTranslations } from 'next-intl';
const {
  Title,
  Text
} = Typography;
const {
  Content
} = Layout;
const SectionTitle = ({
  title
}: {
  title: string;
}) => <Title style={{
  color: '#0077b6',
  fontSize: '36px',
  fontWeight: 'bold'
}} data-sentry-element="Title" data-sentry-component="SectionTitle" data-sentry-source-file="Inicio.tsx">
    {title}
  </Title>;
const FeatureCard = ({
  icon,
  title,
  description
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) => <Col xs={24} sm={12} md={8} data-sentry-element="Col" data-sentry-component="FeatureCard" data-sentry-source-file="Inicio.tsx">
    <Card hoverable style={{
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '10px',
    height: 250,
    padding: 10,
    boxShadow: '0 0 20px 0 rgba(0,0,0,.1)'
  }} data-sentry-element="Card" data-sentry-source-file="Inicio.tsx">
      {icon}
      <Title level={4} style={{
      marginTop: '10px'
    }} data-sentry-element="Title" data-sentry-source-file="Inicio.tsx">
        {title}
      </Title>
      <Text data-sentry-element="Text" data-sentry-source-file="Inicio.tsx">{description}</Text>
    </Card>
  </Col>;
const CompanyLogo = ({
  src,
  alt
}: {
  src: string;
  alt: string;
}) => <div style={{
  width: '150px',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}} data-sentry-component="CompanyLogo" data-sentry-source-file="Inicio.tsx">
    <img src={src} alt={alt} style={{
    maxHeight: '80px',
    maxWidth: '100%',
    objectFit: 'contain'
  }} />
  </div>;
const VideoCard = ({
  videoUrl,
  title
}: {
  videoUrl: string;
  title: string;
}) => <div className="max-w-[300px] max-h-[300px] sm:mr-[10px] sm:mb-[0px] mb-[10px]" data-sentry-component="VideoCard" data-sentry-source-file="Inicio.tsx">
    <div style={{
    position: 'relative',
    paddingBottom: '200px',
    height: 0
  }}>
      <iframe style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }} src={videoUrl} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <Title level={5} style={{
    marginTop: '15px',
    color: '#0077b6'
  }} data-sentry-element="Title" data-sentry-source-file="Inicio.tsx">
      {title}
    </Title>
  </div>;
export default function Home() {
  const t = useTranslations('home');
  return <Content style={{
    padding: '10px'
  }} data-sentry-element="Content" data-sentry-component="Home" data-sentry-source-file="Inicio.tsx">
      <div className="flex flex-col sm:flex-row justify-between items-center px-5" style={{
      height: 350
    }}>
        <div className="flex items-center" style={{
        flex: 1,
        textAlign: 'right',
        height: '100%',
        width: '100%'
      }}>
          <div style={{
          width: '100%'
        }}>
            <Text style={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: 40,
            display: 'block'
          }} data-sentry-element="Text" data-sentry-source-file="Inicio.tsx">
              {t('discoverRoots')}
            </Text>
            <Text style={{
            color: '#fff',
            fontSize: '18px',
            lineHeight: '1.6'
          }} data-sentry-element="Text" data-sentry-source-file="Inicio.tsx">
              {t('introText')}
            </Text>
          </div>
        </div>
      </div>

      <Divider orientation="left" style={{
      fontSize: '28px',
      fontWeight: '700',
      color: '#1f2937'
    }} data-sentry-element="Divider" data-sentry-source-file="Inicio.tsx">
        {t('documentTools')}
      </Divider>

      <Row gutter={[16, 16]} style={{
      marginTop: '20px',
      justifyContent: 'center'
    }} data-sentry-element="Row" data-sentry-source-file="Inicio.tsx">
        <FeatureCard icon={<UserOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('buildTree')} description={t('buildTreeDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<DatabaseOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('storage')} description={t('storageDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<FileAddOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('importTree')} description={t('importTreeDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<ExportOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('exportTree')} description={t('exportTreeDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<BookOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('exclusiveArchive')} description={t('exclusiveArchiveDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<SearchOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('advancedSearch')} description={t('advancedSearchDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<CheckCircleOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('coherenceChecker')} description={t('coherenceCheckerDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<CustomerServiceOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('personalizedSupport')} description={t('personalizedSupportDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
      </Row>

      <Divider orientation="left" style={{
      fontSize: '28px',
      fontWeight: '700',
      color: '#1f2937'
    }} data-sentry-element="Divider" data-sentry-source-file="Inicio.tsx">
        {t('geneticTools')}
      </Divider>

      <Row gutter={[16, 16]} style={{
      marginTop: '20px',
      justifyContent: 'center'
    }} data-sentry-element="Row" data-sentry-source-file="Inicio.tsx">
        <FeatureCard icon={<ProfileOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('individualComparison')} description={t('individualComparisonDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<TeamOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('multipleComparison')} description={t('multipleComparisonDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<GlobalOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('ethnicity')} description={t('ethnicityDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<PartitionOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('yDNA_mtDNA')} description={t('yDNA_mtDNADesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<FileSyncOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('unifyFiles')} description={t('unifyFilesDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<FileSearchOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('segmentSearch')} description={t('segmentSearchDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<CalculatorOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('relationshipProbability')} description={t('relationshipProbabilityDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<TableOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('matrixClustering')} description={t('matrixClusteringDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<LineChartOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('phasing')} description={t('phasingDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<ClusterOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('triangulation')} description={t('triangulationDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<BranchesOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('separateGenes')} description={t('separateGenesDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
        <FeatureCard icon={<DownloadOutlined style={{
        fontSize: '50px',
        color: '#0077b6'
      }} />} title={t('downloadMatches')} description={t('downloadMatchesDesc')} data-sentry-element="FeatureCard" data-sentry-source-file="Inicio.tsx" />
      </Row>

      <div style={{
      marginTop: '10px',
      backgroundColor: '#FFFFFF',
      padding: '20px 0',
      boxShadow: '0 0 20px 0 rgba(0,0,0,.1)',
      borderRadius: 16
    }}>
        <Title level={3} style={{
        textAlign: 'center',
        color: '#0077b6'
      }} data-sentry-element="Title" data-sentry-source-file="Inicio.tsx">
          {t('supportedDNACompanies')}
        </Title>
        <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '40px',
        marginTop: '30px'
      }}>
          <CompanyLogo src="/assets/ancestry.webp" alt="Ancestry" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
          <CompanyLogo src="/assets/23andme.png" alt="23andMe" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
          <CompanyLogo src="/assets/myheritage.webp" alt="MyHeritage" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
          <CompanyLogo src="/assets/familytreedna.webp" alt="FamilyTreeDNA" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
          <CompanyLogo src="/assets/livingdna.webp" alt="LivingDNA" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
          <CompanyLogo src="/assets/genera.png" alt="Genera" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
          <CompanyLogo src="/assets/mundodna.png" alt="MundoDNA" data-sentry-element="CompanyLogo" data-sentry-source-file="Inicio.tsx" />
        </div>
      </div>

      <Divider style={{
      marginTop: 50
    }} data-sentry-element="Divider" data-sentry-source-file="Inicio.tsx" />

      <div style={{
      textAlign: 'center',
      marginTop: '30px',
      padding: '0 20px'
    }}>
        <SectionTitle title={t('specialistTitle')} data-sentry-element="SectionTitle" data-sentry-source-file="Inicio.tsx" />
        <Text style={{
        fontSize: '18px',
        color: '#555',
        marginBottom: '30px',
        display: 'block'
      }} data-sentry-element="Text" data-sentry-source-file="Inicio.tsx">
          {t('specialistText')}
        </Text>

        <div className="sm:flex-row flex-col" style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}>
          <VideoCard videoUrl="https://www.youtube.com/embed/hBvNNXv-GKs" title={t('video1Title')} data-sentry-element="VideoCard" data-sentry-source-file="Inicio.tsx" />
          <VideoCard videoUrl="https://www.youtube.com/embed/SE7Md7iFGsM" title={t('video2Title')} data-sentry-element="VideoCard" data-sentry-source-file="Inicio.tsx" />
          <VideoCard videoUrl="https://www.youtube.com/embed/64rQmxPfS5o" title={t('video3Title')} data-sentry-element="VideoCard" data-sentry-source-file="Inicio.tsx" />
        </div>
      </div>

      <Divider style={{
      marginTop: 30
    }} data-sentry-element="Divider" data-sentry-source-file="Inicio.tsx" />

      <div style={{
      textAlign: 'center',
      marginTop: '30px',
      padding: '0 20px'
    }}>
        <SectionTitle title={t('followUsTitle')} data-sentry-element="SectionTitle" data-sentry-source-file="Inicio.tsx" />
        <Text style={{
        fontSize: '18px',
        color: '#555',
        marginBottom: '30px',
        display: 'block'
      }} data-sentry-element="Text" data-sentry-source-file="Inicio.tsx">
          {t('followUsText')}
        </Text>

        <div className="sm:flex-row flex-col" style={{
        display: 'flex',
        justifyContent: 'space-around',
        gap: 10,
        alignItems: 'center',
        padding: 10,
        backgroundColor: '#dddee1',
        borderRadius: 10,
        marginTop: 30,
        boxShadow: '0 0 20px 0 rgba(0,0,0,.1)'
      }}>
          <iframe className="max-w-[100%] max-h-[350px]" style={{
          width: '500px',
          height: '350px',
          borderRadius: 10
        }} src="https://www.instagram.com/apoiogenealogico/embed/"></iframe>
          <iframe className="max-w-[100%] max-h-[350px]" style={{
          width: '500px',
          height: '350px',
          borderRadius: 10
        }} src="https://www.instagram.com/genealogiaminasgerais/embed/"></iframe>
        </div>

        <Button style={{
        backgroundColor: '#25D366 !important',
        borderColor: '#25D366 !important',
        marginTop: 30,
        marginBottom: 20
      }} type="primary" icon={<WhatsAppOutlined />} size="large" data-sentry-element="Button" data-sentry-source-file="Inicio.tsx">
          {t('joinWhatsApp')}
        </Button>
      </div>
    </Content>;
}