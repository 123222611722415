'use client';

import React from 'react';
import { Layout } from 'antd';
import './ApoioGenealogico.css';
import Rodape from '@/components/Rodape';
import Inicio from '@/components/Inicio';
import Cabecalho from '@/components/Cabecalho';
const {
  Content
} = Layout;
const HomePage = () => {
  return <>
      <Cabecalho data-sentry-element="Cabecalho" data-sentry-source-file="page.tsx" />
      <div style={{
      width: '100%',
      height: '350px',
      backgroundImage: `url(/assets/capa.png)`,
      backgroundSize: 'cover',
      backgroundPosition: '0px 80%',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      zIndex: -1
    }}></div>
      <Layout className={'sm:max-w-[1000px] w-full mx-auto'} style={{
      minHeight: '80vh',
      flex: 1,
      background: 'transparent'
    }} data-sentry-element="Layout" data-sentry-source-file="page.tsx">
        <Content data-sentry-element="Content" data-sentry-source-file="page.tsx">
          <Inicio data-sentry-element="Inicio" data-sentry-source-file="page.tsx" />
        </Content>
      </Layout>

      <Rodape data-sentry-element="Rodape" data-sentry-source-file="page.tsx" />
    </>;
};
export default HomePage;